@import '../../variables';

$borderRadius: 10px;
$bookHeight: 150px;

.booklist-container{
    // padding-left: 100px;
    // padding-right: 100px;
    text-align:left;
    margin-left: 30px; 
    h1{
        margin-top: 0px;
    }
}

.book-container{
    display: inline-flex;
    border-radius:$borderRadius;
    border: 1px solid $lightPurple;
    margin-bottom: 0px;
    margin-right: 15px;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        background-color: $lightestPurple;
    }
    width: 300px;

    /* Clear floats after the columns */
    &.row:after {
        content: "";
        display: table;
        clear: both;
        }

    .column {
        float: left;
        // width: 50%;
        &.cover-container{
            width: fit-content;
            height: $bookHeight;

        }
        &.description-container{
            padding-left: 30px;
            padding-right: 10px;
            width: $bookHeight;
            height: $bookHeight;
            h3{
                font-size: 16px;
                margin-bottom: 2px;
            }
            .book-title{
                line-height: 1.5em;
                font-size: 14px;
                max-height: 4.5em;       /* height is 2x line-height, so two lines will display */
                overflow: hidden;  /* prevents extra lines from being visible */            
                text-overflow: ellipsis;
            }
            .author-text{
                font-size: 12px;
            }
            .price-text{
                color: rgb(56, 56, 56);
            }
        }
    }
      
    
}

.author-text{
    margin-top: 10px;
    margin-bottom: 10px;
    color: $textGrey;
} 

.book-cover{
    height: $bookHeight;
    width: auto;
    border-radius: $borderRadius;
}

.price-text{
    color: $darkPurple;
    font-weight: bold;
    font-size: 14px;
}



.queryInfo{
    color: $textGrey;
    margin-bottom: 20px;
}
.queryInfo span{
    white-space: nowrap;
}
.booklist .noBooks{
    white-space: nowrap;
}

.categoryResultsList{
    margin-bottom: 30px;
}

@media (max-width: 767px){
    .home-container .column.booklist{
        width: auto !important; 
    }
    .hide{
        display: none;
    }
}