* {
  font-family: "Poppins";
}

.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: 'Poppins';
  src: local("Poppins"), url("./fonts/Poppins/Poppins-Regular.ttf");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
}

.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.dl-button {
  color: white;
  border-radius: 10px;
  padding: 5px;
  margin-top: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid transparent;
  background-color: #4f46e5;
  color: white;
}

.dl-button.white {
  border: 1px solid #4f46e5;
  background-color: white;
  color: #4f46e5;
}

.dl-button:not(.deactivated) {
  cursor: pointer;
}

.dl-button:not(.deactivated):hover {
  border: 1px solid #4f46e5;
  color: #4f46e5;
  background-color: white;
}

.dl-button:not(.deactivated).white:hover {
  border: 1px solid white;
  color: white;
  background-color: #4f46e5;
}

.dl-a {
  text-decoration: none;
  color: inherit;
}
