@import '../../variables';

.navbar-container{
    padding: 10px;
    // margin-top: 10px;
    border-bottom: 1px solid #bab6f58b;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    display: flex;
    padding-left: 30px;
    padding-right: 30px;

    .floating{
        display: flexbox;
    }
}


.navlink{
    margin-right: 10px;
    margin-left: 30px;
    font-size: 16px;

}

.libLogo{
    height: 28px;
}

.openDesklamp{
    border-radius: 10px;
    padding: 5px;
    background-color: $darkPurple;
    border: 1px solid transparent;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    transition: 0.3s;
    &:hover{
        border: 1px solid $darkPurple;
        background-color: white;
        color: $darkPurple
    }
}

.signIn{
    border:none;
    background-color: transparent;
    cursor: pointer;
}

.logoContainer{
    display: flex;
    justify-content: center;

}

.account-popup{
    position: absolute;
    padding: 10px;
    background-color: $uberLightPurple;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 25px;
    margin-top: 6px;
    &.show{
        display: block
    }
    &.hide{
        display: none;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        right: 35px;
        top: -10px;
        border-bottom: 10px solid $uberLightPurple;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
   }

   .account-option{
       cursor: pointer;
       transition: 0.3s;
       margin-bottom: 5px;
       margin-top: 5px;
       &:hover{
           color: $darkPurple;
       }
   }

}

.openDesklamp-a{
    text-decoration: none;
    color: white;
    &:hover{
        color: $darkPurple
    }
}

@media (max-width:767px){
    .navbar-container{
        display: none;
    }
    .container{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: -webkit-center;
    }
    .navbar-container-mobile{
        background-color: #675bef;
        padding: 0.5rem;
        border-radius: 0.375rem;
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
        justify-content:space-between;
        align-items: center;
        display: flex;
        // max-width: 80rem;
        // min-width: 30rem;
        width: 80%;
    }
    .logoContainer-mobile{
        display: flex;
        justify-content: center;
        padding-top: 5px;
        align-items:center;
        justify-items: center;

        // margin-left: 5rem;
    }
    .navlink-signIn-mobile{
        border:none;
        background-color: transparent;
        cursor: pointer;
        font-size: 16px;
        color: #fff;
        // justify-content: center;
        // align-items: center;
        // padding-top: 0.375rem;
        white-space: nowrap;
    }
    .ham-menu{
        color: #fff;
        background-color: #675bef;
        border: none;
        padding: 0.5rem;
        
    }
    .hideMenu{
        display: none;
    }
    .showMenu{
        display: block;
    }
    .libLogo{
        height: 25px
    }
    .account-popup{
        margin-left: -25px;
        
    }
    .account-icon{
        border-radius: 50%;
        border: 1px solid #fff;
        padding: 1px 8px;  
    }
}
@media (min-width: 767px){
    .navbar-container-mobile{
        display: none;
    }
    .showMenu{
        display: none;
    }
    .hideMenu{
        display: none;
    }
}