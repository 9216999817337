@import './variables';

*{
    font-family: "Poppins"

}

.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url('./fonts/Poppins/Poppins-Regular.ttf');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a{
    text-decoration: none;
}

.column {
    float: left;
    width: 50%;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

.dl-button{
    color: white;
    border-radius: 10px;
    padding: 5px;
    margin-top: 10px;
    transition: 0.3s;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;

    border: 1px solid transparent;
    background-color: $darkPurple;
    color: white;

    &.white{
        border: 1px solid $darkPurple;
        background-color: white;
        color: $darkPurple;
    }

    &:not(.deactivated){
        cursor: pointer;

        &:hover{
            border: 1px solid $darkPurple;
            color: $darkPurple;
            background-color: white;
        }

        &.white{
            &:hover{
                border: 1px solid white;
                color: white;
                background-color: $darkPurple;
            }
        }
    }

    
    
}

.dl-a{
    text-decoration: none;
    color: inherit;
}