.bookpage-container .coverpage {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-left: 50px;
  padding-right: 50px;
}

.bookpage-container .description {
  width: calc(95% - 312px);
  text-align: left;
}

.bookpage-container .description .book-title {
  margin: 0px;
  margin-bottom: 10px;
}

.bookpage-container .description .author-text {
  margin-bottom: 20px;
}

.bookpage-container .description .price-text {
  font-size: 18px;
  margin-right: 20px;
  display: inline;
}

.bookpage-container .description .description-bookpage {
  margin-top: 20px;
}

.bookpage-cover {
  height: 300px;
}

.bookpage-cover.success {
  height: 250px;
  float: right;
}

.success-description {
  width: 85%;
}

.successMessage {
  text-align: left;
  border: 2px solid #55af55;
  padding: 10px;
  border-radius: 10px;
  border-left: 10px solid #55af55;
  margin-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.successMessage h1 {
  color: #55af55;
  font-size: 20px;
}

.success-btn {
  margin-right: 15px;
}

.buy-button-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.loading_gif {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.message-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 767px) {
  .description-mobile {
    display: none;
  }
  .message-end {
    display: none;
  }
}

@media (min-width: 1025px) {
  .description-bookpage-tab {
    display: none;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .bookpage-container .coverpage {
    padding-left: 0px;
  }
  .description-bookpage {
    display: none;
  }
  .description-book {
    margin-top: 10px;
  }
  .bookpage-container .description {
    width: calc(100% - 262px);
  }
  .description-bookpage-tab {
    float: left;
    text-align: justify;
    margin-top: 10px;
  }
  .successMessage {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 0.8rem;
  }
  .success-btn {
    margin-right: 1rem;
    font-size: 0.8rem;
  }
}

@media (min-width: 767px) and (max-width: 879px) {
  .library-btn {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1073px) {
  .success-btn {
    margin-right: 1rem;
    font-size: 0.8rem;
  }
}

@media (max-width: 767px) {
  .bookpage-container .description {
    text-align: justify;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    padding-left: 5%;
  }
  .description-bookpage {
    width: 90%;
  }
  .description-mobile {
    text-align: left;
    margin-left: 15px;
    overflow: hidden;
    white-space: wrap;
  }
  .description-book {
    display: none;
  }
  .bookpage-container .coverpage {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding-left: 4px;
    padding-right: 4px;
    width: auto;
  }
  .bookpage-container .bookpage-cover {
    height: 225px;
  }
  .book-title {
    font-weight: bold;
  }
  .successMessage {
    padding-left: 3px;
    padding-right: 3px;
    font-size: 0.8rem;
  }
  .bookpage-container {
    padding-left: 5px;
    padding-right: 5px;
  }
  .end-btns, .endpara {
    display: none;
  }
  .message-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .mobile-btns {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .mobile-btns button {
    font-size: 0.8rem;
    font-weight: bolder;
  }
  .description-bookpage-tab {
    display: none;
  }
  .success-description {
    padding-left: 5px;
    font-size: 0.8rem;
  }
}
