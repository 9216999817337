
// @import '../../styles/variables';

$borderRadius: 10px;
$messageWidth: 40%;
$scale:0.3;
$height: 440;

.desklamp-logo-auth{
    width: 150px;
    margin-bottom: 10px;
}

.close-authbox{
    z-index:2;
    position:absolute;
    height:12px;
    width:12px;
    top:10px;
    right:10px;
    padding:5px;
    border-radius:50%;

    &:hover{
        background-color: rgb(190, 190, 190);
    }
}

.authbox-container{
    z-index:101;
    font-size: 30px;
    // position: fixed;
    // top:50%;
    // left:50%;
    border-radius: 10px;
    box-shadow: $boxShadow;
}

.authbox-column {
    float: left;
    height:100%;
}

.authbox-message{

    width: $messageWidth;
    position:relative;
    background-color: $readerBgColor;
    // background: url('./auth-background.jpeg') repeat 24px 13px;
    // background-repeat: repeat;
    // background-size: cover; 

    // filter: sepia(100%) hue-rotate(190deg) saturate(500%);

    
    // .thinkerEle{
    //     max-width:100%;
    //     height: auto;
    //     object-fit: contain;
    //     transform: translateY(80px);
        
    // }

    .auth-logo-Kitab{
        height: 108px;
        width: 108px;
        border-radius: 50%;
        margin-left: 25%;
    }
  
    color:$readerBgColor;
    text-align:left;
    // display: flex;
    align-items: center;
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
    
}

.authbox-form{
    width:100% - $messageWidth;
    background-color: $readerBgColor;
    color:$darkClipboardColor;
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    position: relative;
    &.mobile{
        width: 100%;
        border-top-left-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }
}

.proceed-guest{
    margin-top: 15px;
    font-size: 12px;
}

  
  /* Clear floats after the columns */
  .authbox-container:after {
    content: "";
    display: table;
    clear: both;
  }

  .authbox-form-container{
    //   border:1px solid green;
      width:100%;
      position: absolute;
      top: 50%;
      text-align: center;
      transform: translate(0, -50%);
      .auth-dropdown{
        position: fixed;
        height: 150px;
        background-color: $readerBgColor;
        box-shadow: $boxShadow;
        z-index: 5;
        border-radius: 5px;
        width: 270px;
        font-size: 14px;
        bottom: -60px;
        left: 45px;
        overflow-y: auto;


        .auth-option{
            user-select: none;
            text-align: left;
            padding: 3px;
            // padding-left: 20px;

            margin-top:2px;
            margin-bottom: 2px;
            margin:5px;

            border-radius: 5px;
            &:hover{
                background-color: $lightHover;
            }
        }
    
    }
    .authbox-input{
        border:1px solid transparentize($darkClipboardColor,0.7);
        border-radius:5px;
        margin-bottom: 0px;
        width:250px;
        padding:10px;
        // background-color: rgb(232, 240, 254);
        &:focus{
            border:1px solid $darkClipboardColor
        }

        &.mini{
            width: 110px;
        }
        &.first-name{
            margin-right:4px;
        }
        &.last-name{
            margin-left: 4px;
        }
    }
    .authbox-link{
        display: block;
        font-size:12px;
        margin-top:5px;
        font-weight:bold;
        cursor:pointer;
        color:$desklampButtonBg;

        &.inline{
            display: inline-block;
        }
    }
    .authbox-link-inline{
        display:inline
    }
    .authbox-text{
        display: block;
        color:$darkReaderBgColor;
        font-size:12px;
        margin-top:15px;
        font-weight:bold;
        &.social-message{
            font-weight:normal;
            padding-left:50px;
            padding-right:50px;
            margin:0 auto;
        }
        &.comingsoon{
            margin-top:-5px;
        }
    }
    .authbox-p{
        font-size:14px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .authbox-p-head{
        font-size: 18px;
        font-family: Arial;
        color: #4279c7;
    }
    .authbox-button{
        width:270px;
        padding:10px;
        font-weight:bold;
        border-radius:5px;
        margin-top:15px;
        cursor:pointer;
        &.signin{
            color:white;
            background-color: $desklampButtonBg;
            position: relative;
        }
        &.leavebottom{
            margin-bottom: 10px;
        }
        &.facebook-signin{
            color:white;
            // background-color:#415a98;
            background-color: grey;
            cursor:default;

        }
        &.google-signin{
            border:1px solid grey;
            margin-top:0px;
            margin-bottom:20px;
            // color:$darkReaderBgColor;
            color:grey;
            cursor:default;
        }

       
        
        .auth-logo{
            height:18px;
            width:18px;
            position:absolute;
            left:60px;
            filter:grayscale(1)
        }

        .loading-auth{
            height:20px;
            width: 20px;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 50px;
        }
        


    }
    .authbox-or {
        overflow: hidden;
        text-align: center;
        font-size:12px;
        margin-top:0px;
        font-weight:bold;
    }
    .authbox-or:before,
    .authbox-or:after {
        background-color: transparentize($darkReaderBgColor, 0.5);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 40%;
    }
    .authbox-or:before {
        right: 0.5em;
        margin-left: -50%;
    }
    .authbox-or:after {
        left: 0.5em;
        margin-right: -50%;
    }
    

  }

//   .authbox-message-container{
//     position: absolute;
//     top: 50%;
//     transform: translate(0, -50%);
//     width:100%;
//     // background-color:rgb(26, 22, 59);
    

//     .authbox-message-header{
//         // margin-left:1px;
//         font-weight: bold;
//         font-size:24px;
//         display:block;
//         margin:15px;
//     }
//     .authbox-message-rule{
//         // border-top: 4px solid rgb(45, 202, 255);
//         width:100%;
//         background: none;
//         margin-top:0px;
//         margin-left:15px;
//         width:120px;

//     }
//     .authbox-message-text{
//         font-weight:bold;
//         font-size:14px;
//         margin:15px;
//         margin-top:25px;
//         width:80%;
//     }
// }


.authbox-message-container2{
    background-image: linear-gradient(#6b6de0, #4b65d9);
    margin:10px;
    height:$height - 2*10px - 2*20px;
    padding: 20px;
    border-radius: 10px;
    user-select: none;

    .authbox-message-text{
        margin-top:80px;
    }

    .authbox-message-header{
        font-weight: bold;
        font-size:24px;
        font-family: Arial;

    }

    .authbox-message-subheader{
        font-size: 14px;
    }
    .authbox-message-footer{
        padding:10px;
        border-radius: 10px;
        background-color: #7e7fdf;
        position: absolute;
        bottom: 30px;
        width: 180px - 2*10px ;
        cursor: pointer;
        // font-family: Arial;
    }

    .leftFooter{
        
        font-size: 12px;
        margin:0px;
    }

    .leftFooterAction{
        font-size: 14px;
        margin:0;
        margin-top:2px;
        font-weight: bold;
        font-family: Arial;
    }

}
.auth-hr{
    border:2px solid rgb(49, 186, 231);
    margin:0;
    margin-left:15px;
    margin-right:50px;
    border-radius: 1px;
}

.authbox-error{
    color:$lightDesklampRed;
    font-size: 14px;
    font-weight: bold;
    margin:0;
    margin-bottom: 10px;

    &.message{
        color:$desklampButtonBg
    }
}

.authbox-greet{
    color:$desklampButtonBg;
    font-size: 14px;
    font-weight: bold;
    margin:0;
    margin-bottom: 10px;
}

.authbox-regmes{
    color:$lightDesklampGreen;
    font-size: 14px;
    font-weight: bold;
}

.authbox-tos{
    color:rgb(78, 78, 78);
    margin-top:10px;
    font-size: 10px;
    padding-left:40px;
    padding-right:40px;
}

.authbox-initials{
    font-size: 24px;
    font-weight: bold;
    color:$readerBgColor;
    background-image: linear-gradient(#6b6de0, #4b65d9);;
    width:fit-content;
    padding: 10px;
    border-radius:10px;
    margin: 0 auto;

}

.authbox-button-link{
    text-decoration: none;
    color: white;
}

.success-popup{
    position: fixed;
    top: 20px;
    left: 45%;
    z-index:101;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 3px;
    background-color: $lightDesklampGreen;

    &.dark{
        background-color: $darkDesklampGreen;
    }
    
}