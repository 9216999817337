
@import '../../variables';



.footer-container{
    border-top: 1px solid #bab6f58b;
    padding-top: 40px;
    color: $textGrey;
    margin-top: 30px;
    padding-left: 160px;
    padding-right: 160px;

}

.upper-footer{
    padding-bottom: 30px;
    justify-content: space-between;
    display: flex;

    .column{
        // display: flex;
    }

    .logoColumn{
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .textColumn{
        width: 20%;
        padding-left: 20px;
        text-align: left;
        align-items: center;
        justify-content: center;
    }
}

.footer-header{
    color: $darkPurple;
}

.footer-list{
    list-style: none;
    padding-left: 0px;
    
}

.footerLogo{
    height: 28px;
}

.lower-footer{
    justify-content: space-between;
    align-items: center;
    display: flex;
    // padding-left: 30px;
    // padding-right: 30px;
    font-size: 14px;


    .lower-cont{
        display: inline;
    }
}

.heart{
    color: $mainPurple;
    margin-left: 5px;
    margin-right: 5px;
}

.socialIcon{
    width: 24px;
    height: 24px;
    margin-left: 20px;
    fill: grey;
    &.bigger{
        height: 24px;
        width: 24px;
    }
    &:hover{
        fill: $darkPurple;
    }

}

.footer-link{
    color: $textGrey;
    margin-left: 5px;
    text-decoration: none;
    &:hover{
        text-decoration: underline;

    }

}

@media (max-width: 480px){
    .footer-container{
        padding-left: 0px;
        align-items: center;
        justify-content: center; 
    }
    .upper-footer .logoColumn{
        display: none;
    }
    .upper-footer .textColumn{
        // width: 150px;
        padding-left: 15px;
        margin-left: auto;
        margin-right: auto;
        // padding-right: 25px;
        width:100%;
    }
     .upper-footer .textColumn .footer-list{
        white-space: nowrap;
        font-size: small;
    }
    .lower-footer{
        white-space: nowrap;
        overflow: hidden;
        display: inline-grid;
        padding-left: 45px;
        align-items: center;
        justify-content: center;
    }
    .lower-footer .lower-cont{
        padding-bottom: 5px;
    }
    .lower-footer .lower-cont .flex{
        display: none;
    }
}
@media (min-width: 481px) and (max-width: 767px){
    .footer-container{
        padding-left: 5rem;
        align-items: center;
        justify-content: center;
        // margin-left: ; 
    }
    .upper-footer .logoColumn{
        display: none;
    }
    .upper-footer .textColumn{
        // width: 150px;
        padding-left: 15px;
        margin-left: auto;
        margin-right: auto;
        // padding-right: 25px;
        width:100%;
    }
     .upper-footer .textColumn .footer-list{
        white-space: nowrap;
        font-size: small;
    }
    .lower-footer{
        white-space: nowrap;
        overflow: hidden;
        display: inline-grid;
        padding-left: 45px;
        align-items: center;
        justify-content: center;
    }
    .lower-footer .lower-cont{
        padding-bottom: 5px;
    }
    .lower-footer .lower-cont .flex{
        display: none;
    }
}

@media (min-width: 768px){
    .footer-container{
        padding-left: 50px;
        padding-right: 32px
    }
    .upper-footer .textColumn .footer-list{
        white-space: nowrap;
    }
    .lower-footer{
        align-items: center;
        justify-content: center;
    }
    .lower-footer .lower-cont{
        white-space: nowrap;
        padding: 1.5rem;
    }
    .socialIcon{
        margin-left: 10px;
    }
}

