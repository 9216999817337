/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.desklamp-logo-auth {
  width: 150px;
  margin-bottom: 10px;
}

.close-authbox {
  z-index: 2;
  position: absolute;
  height: 12px;
  width: 12px;
  top: 10px;
  right: 10px;
  padding: 5px;
  border-radius: 50%;
}

.close-authbox:hover {
  background-color: #bebebe;
}

.authbox-container {
  z-index: 101;
  font-size: 30px;
  /* position: fixed;
  top: 50%;
  left: 50%; */
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}

.authbox-column {
  float: left;
  height: 100%;
}

.authbox-message {
  width: 40%;
  position: relative;
  background-color: #f2f2f7;
  color: #f2f2f7;
  text-align: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.authbox-message .auth-logo-Kitab {
  height: 108px;
  width: 108px;
  border-radius: 50%;
  margin-left: 25%;
}

.authbox-form {
  width: 60%;
  background-color: #f2f2f7;
  color: #212231;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}

.authbox-form.mobile {
  width: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.proceed-guest {
  margin-top: 15px;
  font-size: 12px;
}

/* Clear floats after the columns */
.authbox-container:after {
  content: "";
  display: table;
  clear: both;
}

.authbox-form-container {
  width: 100%;
  position: absolute;
  top: 50%;
  text-align: center;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.authbox-form-container .auth-dropdown {
  position: fixed;
  height: 150px;
  background-color: #f2f2f7;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  z-index: 5;
  border-radius: 5px;
  width: 270px;
  font-size: 14px;
  bottom: -60px;
  left: 45px;
  overflow-y: auto;
}

.authbox-form-container .auth-dropdown .auth-option {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: left;
  padding: 3px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin: 5px;
  border-radius: 5px;
}

.authbox-form-container .auth-dropdown .auth-option:hover {
  background-color: #BAB6F5;
}

.authbox-form-container .authbox-input {
  border: 1px solid rgba(33, 34, 49, 0.3);
  border-radius: 5px;
  margin-bottom: 0px;
  width: 250px;
  padding: 10px;
}

.authbox-form-container .authbox-input:focus {
  border: 1px solid #212231;
}

.authbox-form-container .authbox-input.mini {
  width: 110px;
}

.authbox-form-container .authbox-input.first-name {
  margin-right: 4px;
}

.authbox-form-container .authbox-input.last-name {
  margin-left: 4px;
}

.authbox-form-container .authbox-link {
  display: block;
  font-size: 12px;
  margin-top: 5px;
  font-weight: bold;
  cursor: pointer;
  color: #665AEF;
}

.authbox-form-container .authbox-link.inline {
  display: inline-block;
}

.authbox-form-container .authbox-link-inline {
  display: inline;
}

.authbox-form-container .authbox-text {
  display: block;
  color: #34353f;
  font-size: 12px;
  margin-top: 15px;
  font-weight: bold;
}

.authbox-form-container .authbox-text.social-message {
  font-weight: normal;
  padding-left: 50px;
  padding-right: 50px;
  margin: 0 auto;
}

.authbox-form-container .authbox-text.comingsoon {
  margin-top: -5px;
}

.authbox-form-container .authbox-p {
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
}

.authbox-form-container .authbox-p-head {
  font-size: 18px;
  font-family: Arial;
  color: #4279c7;
}

.authbox-form-container .authbox-button {
  width: 270px;
  padding: 10px;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.authbox-form-container .authbox-button.signin {
  color: white;
  background-color: #665AEF;
  position: relative;
}

.authbox-form-container .authbox-button.leavebottom {
  margin-bottom: 10px;
}

.authbox-form-container .authbox-button.facebook-signin {
  color: white;
  background-color: grey;
  cursor: default;
}

.authbox-form-container .authbox-button.google-signin {
  border: 1px solid grey;
  margin-top: 0px;
  margin-bottom: 20px;
  color: grey;
  cursor: default;
}

.authbox-form-container .authbox-button .auth-logo {
  height: 18px;
  width: 18px;
  position: absolute;
  left: 60px;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.authbox-form-container .authbox-button .loading-auth {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 50px;
}

.authbox-form-container .authbox-or {
  overflow: hidden;
  text-align: center;
  font-size: 12px;
  margin-top: 0px;
  font-weight: bold;
}

.authbox-form-container .authbox-or:before,
.authbox-form-container .authbox-or:after {
  background-color: rgba(52, 53, 63, 0.5);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 40%;
}

.authbox-form-container .authbox-or:before {
  right: 0.5em;
  margin-left: -50%;
}

.authbox-form-container .authbox-or:after {
  left: 0.5em;
  margin-right: -50%;
}

.authbox-message-container2 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#6b6de0), to(#4b65d9));
  background-image: linear-gradient(#6b6de0, #4b65d9);
  margin: 10px;
  height: 380px;
  padding: 20px;
  border-radius: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.authbox-message-container2 .authbox-message-text {
  margin-top: 80px;
}

.authbox-message-container2 .authbox-message-header {
  font-weight: bold;
  font-size: 24px;
  font-family: Arial;
}

.authbox-message-container2 .authbox-message-subheader {
  font-size: 14px;
}

.authbox-message-container2 .authbox-message-footer {
  padding: 10px;
  border-radius: 10px;
  background-color: #7e7fdf;
  position: absolute;
  bottom: 30px;
  width: 160px;
  cursor: pointer;
}

.authbox-message-container2 .leftFooter {
  font-size: 12px;
  margin: 0px;
}

.authbox-message-container2 .leftFooterAction {
  font-size: 14px;
  margin: 0;
  margin-top: 2px;
  font-weight: bold;
  font-family: Arial;
}

.auth-hr {
  border: 2px solid #31bae7;
  margin: 0;
  margin-left: 15px;
  margin-right: 50px;
  border-radius: 1px;
}

.authbox-error {
  color: #db5461;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}

.authbox-error.message {
  color: #665AEF;
}

.authbox-greet {
  color: #665AEF;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
}

.authbox-regmes {
  color: #7ac74f;
  font-size: 14px;
  font-weight: bold;
}

.authbox-tos {
  color: #4e4e4e;
  margin-top: 10px;
  font-size: 10px;
  padding-left: 40px;
  padding-right: 40px;
}

.authbox-initials {
  font-size: 24px;
  font-weight: bold;
  color: #f2f2f7;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#6b6de0), to(#4b65d9));
  background-image: linear-gradient(#6b6de0, #4b65d9);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
  margin: 0 auto;
}

.authbox-button-link {
  text-decoration: none;
  color: white;
}

.success-popup {
  position: fixed;
  top: 20px;
  left: 45%;
  z-index: 101;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  background-color: #7ac74f;
}

.success-popup.dark {
  background-color: #59ffa0;
}
