.footer-container {
  border-top: 1px solid #bab6f58b;
  padding-top: 40px;
  color: #6b7280;
  margin-top: 30px;
  padding-left: 160px;
  padding-right: 160px;
}

.upper-footer {
  padding-bottom: 30px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.upper-footer .logoColumn {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.upper-footer .textColumn {
  width: 20%;
  padding-left: 20px;
  text-align: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.footer-header {
  color: #4f46e5;
}

.footer-list {
  list-style: none;
  padding-left: 0px;
}

.footerLogo {
  height: 28px;
}

.lower-footer {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
}

.lower-footer .lower-cont {
  display: inline;
}

.heart {
  color: #665AEF;
  margin-left: 5px;
  margin-right: 5px;
}

.socialIcon {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  fill: grey;
}

.socialIcon.bigger {
  height: 24px;
  width: 24px;
}

.socialIcon:hover {
  fill: #4f46e5;
}

.footer-link {
  color: #6b7280;
  margin-left: 5px;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .footer-container {
    padding-left: 0px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .upper-footer .logoColumn {
    display: none;
  }
  .upper-footer .textColumn {
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .upper-footer .textColumn .footer-list {
    white-space: nowrap;
    font-size: small;
  }
  .lower-footer {
    white-space: nowrap;
    overflow: hidden;
    display: -ms-inline-grid;
    display: inline-grid;
    padding-left: 45px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .lower-footer .lower-cont {
    padding-bottom: 5px;
  }
  .lower-footer .lower-cont .flex {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .footer-container {
    padding-left: 5rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .upper-footer .logoColumn {
    display: none;
  }
  .upper-footer .textColumn {
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .upper-footer .textColumn .footer-list {
    white-space: nowrap;
    font-size: small;
  }
  .lower-footer {
    white-space: nowrap;
    overflow: hidden;
    display: -ms-inline-grid;
    display: inline-grid;
    padding-left: 45px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .lower-footer .lower-cont {
    padding-bottom: 5px;
  }
  .lower-footer .lower-cont .flex {
    display: none;
  }
}

@media (min-width: 768px) {
  .footer-container {
    padding-left: 50px;
    padding-right: 32px;
  }
  .upper-footer .textColumn .footer-list {
    white-space: nowrap;
  }
  .lower-footer {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .lower-footer .lower-cont {
    white-space: nowrap;
    padding: 1.5rem;
  }
  .socialIcon {
    margin-left: 10px;
  }
}
