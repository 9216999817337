.navbar-container {
  padding: 10px;
  border-bottom: 1px solid #bab6f58b;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
}

.navbar-container .floating {
  display: flexbox;
}

.navlink {
  margin-right: 10px;
  margin-left: 30px;
  font-size: 16px;
}

.libLogo {
  height: 28px;
}

.openDesklamp {
  border-radius: 10px;
  padding: 5px;
  background-color: #4f46e5;
  border: 1px solid transparent;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.openDesklamp:hover {
  border: 1px solid #4f46e5;
  background-color: white;
  color: #4f46e5;
}

.signIn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.logoContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.account-popup {
  position: absolute;
  padding: 10px;
  background-color: #eaedf9;
  font-size: 14px;
  border-radius: 5px;
  margin-left: 25px;
  margin-top: 6px;
}

.account-popup.show {
  display: block;
}

.account-popup.hide {
  display: none;
}

.account-popup:before {
  content: "";
  display: block;
  position: absolute;
  right: 35px;
  top: -10px;
  border-bottom: 10px solid #eaedf9;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.account-popup .account-option {
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-bottom: 5px;
  margin-top: 5px;
}

.account-popup .account-option:hover {
  color: #4f46e5;
}

.openDesklamp-a {
  text-decoration: none;
  color: white;
}

.openDesklamp-a:hover {
  color: #4f46e5;
}

@media (max-width: 767px) {
  .navbar-container {
    display: none;
  }
  .container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: -webkit-center;
  }
  .navbar-container-mobile {
    background-color: #675bef;
    padding: 0.5rem;
    border-radius: 0.375rem;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 80%;
  }
  .logoContainer-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 5px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    justify-items: center;
  }
  .navlink-signIn-mobile {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    white-space: nowrap;
  }
  .ham-menu {
    color: #fff;
    background-color: #675bef;
    border: none;
    padding: 0.5rem;
  }
  .hideMenu {
    display: none;
  }
  .showMenu {
    display: block;
  }
  .libLogo {
    height: 25px;
  }
  .account-popup {
    margin-left: -25px;
  }
  .account-icon {
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 1px 8px;
  }
}

@media (min-width: 767px) {
  .navbar-container-mobile {
    display: none;
  }
  .showMenu {
    display: none;
  }
  .hideMenu {
    display: none;
  }
}
