@import '../../variables';

.home-container{
    margin-top: 30px;
    .column {
        float: left;
        &.sidepanel{
            width: 250px;
        }
        &.booklist{
            width: calc(100% - 300px);
        }
    }
      
      /* Clear floats after the columns */
    &.row:after {
        content: "";
        display: table;
        clear: both;
    }
}

.sidepanel-container{
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    // border-right: 1px solid $veryLightPurple;
    // background-color: $veryLightPurple;


    h3{
        color: $darkPurple;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.category-container{
    h3{
        font-size: 16px;
    }
}

.sub-categories{
    margin-bottom: 20px;
}

.subcat-title{
    text-decoration: none;
    margin-bottom: 5px;
    &.active{
        color: $darkPurple;
    }
    &.inactive{
        color: $textGrey;
    }
}

@media (max-width: 767px){
    .sidepanel-container{
        display: none;
    }
}