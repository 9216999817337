.booklist-container {
  text-align: left;
  margin-left: 30px;
}

.booklist-container h1 {
  margin-top: 0px;
}

.book-container {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 10px;
  border: 1px solid #e0e7ff;
  margin-bottom: 0px;
  margin-right: 15px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 300px;
  /* Clear floats after the columns */
}

.book-container:hover {
  background-color: #bab6f55f;
}

.book-container.row:after {
  content: "";
  display: table;
  clear: both;
}

.book-container .column {
  float: left;
}

.book-container .column.cover-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 150px;
}

.book-container .column.description-container {
  padding-left: 30px;
  padding-right: 10px;
  width: 150px;
  height: 150px;
}

.book-container .column.description-container h3 {
  font-size: 16px;
  margin-bottom: 2px;
}

.book-container .column.description-container .book-title {
  line-height: 1.5em;
  font-size: 14px;
  max-height: 4.5em;
  /* height is 2x line-height, so two lines will display */
  overflow: hidden;
  /* prevents extra lines from being visible */
  text-overflow: ellipsis;
}

.book-container .column.description-container .author-text {
  font-size: 12px;
}

.book-container .column.description-container .price-text {
  color: #383838;
}

.author-text {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #6b7280;
}

.book-cover {
  height: 150px;
  width: auto;
  border-radius: 10px;
}

.price-text {
  color: #4f46e5;
  font-weight: bold;
  font-size: 14px;
}

.queryInfo {
  color: #6b7280;
  margin-bottom: 20px;
}

.queryInfo span {
  white-space: nowrap;
}

.booklist .noBooks {
  white-space: nowrap;
}

.categoryResultsList {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .home-container .column.booklist {
    width: auto !important;
  }
  .hide {
    display: none;
  }
}
