.home-container {
  margin-top: 30px;
  /* Clear floats after the columns */
}

.home-container .column {
  float: left;
}

.home-container .column.sidepanel {
  width: 250px;
}

.home-container .column.booklist {
  width: calc(100% - 300px);
}

.home-container.row:after {
  content: "";
  display: table;
  clear: both;
}

.sidepanel-container {
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.sidepanel-container h3 {
  color: #4f46e5;
  margin-top: 5px;
  margin-bottom: 5px;
}

.category-container h3 {
  font-size: 16px;
}

.sub-categories {
  margin-bottom: 20px;
}

.subcat-title {
  text-decoration: none;
  margin-bottom: 5px;
}

.subcat-title.active {
  color: #4f46e5;
}

.subcat-title.inactive {
  color: #6b7280;
}

@media (max-width: 767px) {
  .sidepanel-container {
    display: none;
  }
}
